<template>
  <div
    id="app"
    :class="[browserClassname, flavorClass]"
  >
    <RouterView
      v-if="showRouterView"
      :auth="auth"
      :authenticated="auth.isAuthenticated()"
      transition
      transition-mode="out-in"
      class="h-100 w-100"
    />
    <FeedbackModalComponent
      :is-open="isWatchlistedModalOpen"
      :title="$t('Carsharing.Common.WatchlistedModal.title')"
      :description="$t('Carsharing.Common.WatchlistedModal.text')"
      :buttons="[]"
      :image="genericUserBlock"
    />
    <Loading v-if="isLoaderActive && (requireAuth || requireLoader)" />
    <UseMobileComponent v-if="!requestByMobile" />
    <MuiFullScreen class="emobg-background-color-white" />
    <FeedbackModalComponent
      :is-open="modals.active.isOpen"
      v-bind="modals.active"
    />
    <EnvLabel />
  </div>
</template>

<script>
import Vue, { computed } from 'vue';
import moment from 'moment';
// Lodash imports
import concat from 'lodash/concat';
import delay from 'lodash/delay';
import each from 'lodash/each';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import reject from 'lodash/reject';
import result from 'lodash/result';
import invoke from 'lodash/invoke';

import { MuiAlgoliaPlugin } from '@emobg/motion-ui';
import { mapGetters, mapMutations } from 'vuex';
import { aclService, authService } from '@emobg/access-utils';
import { external } from '@emobg/web-api-client';
import {
  DELAY,
  getIdpUuid,
  getValue,
  LOG_TYPE,
  logger,
  navigationErrorHandler,
  rehydrate,
  toLowercaseString,
} from '@emobg/web-utils';
import Translations from '@/translations';
import config from '@/config';

// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';
import LoaderMixin from '@/mixins/Loader';
import SegmentMixin from '@/mixins/Segment';

// ported mixins from removed AuthenticatedUserMixin mixin
import BookingTypeMixin from '@/mixins/BookingType';
import ProfileMixin from '@/mixins/Profile';
import TariffMixin from '@/mixins/Tariff';
import UserInsuranceMixin from '@/mixins/UserInsurance';
import CompanyMixin from '@/mixins/Company';
import CSOperatorMixin from '@/mixins/CSOperator';

// Services
import siftScienceSnippet from '@/services/SiftScienceSnippet';
import languageService from '@/services/Language';
import { browserClassname } from '@/utils/browsers.util';
import {
  createLaunchDarklyInstance, getFlags, getInstancePayload, toCamelCaseFlags,
} from '@/services/FeatureFlag';
import { validateIsUserWatchlisted, validateUserDetailsResponse } from '@/utils/appUtils';
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
// Libraries
import { initAuthAxiosClientConfig, initGuestAxiosClientConfig } from '@/vue/api/api.service';
// Store
import {
  fetchMyActiveBookings,
  fetchMyRequestBookings,
  getMyActiveBookings,
  getMyRequestBookings,
} from '@/stores/Booking/BookingMapper';
import BookingRouteNames from '@Bookings/router/routes-names';
import EmailValidationRouteNames from '@Account/UserEmailVerification/router/routes-names';

import { employeePolicy, getEmployeePolicy, isOpenOrDedicatedFleet } from '@/stores/Company/CompanyMapper';

// Constants
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import { genericUserBlock } from '@/utils/publicImages';

// Composables
import { useUserData } from '@/composable/User/useUserData';

import {
  GETTERS as USERDATA_GETTERS,
  MUTATIONS as USERDATA_MUTATIONS,
  nameSpace as USERDATA_NAMESPACE,
} from '@/vue/stores/UserData/UserDataStore';

import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import { genericErrorArgs } from '@/constants/defaultModalArgs';
import { useAuth } from '@/composable/Api/Auth/auth';
import { getHotjarPayload, identifyHotjarUser, shouldBeIdentifiedInHotjar } from '@/utils/hotjar-helpers';

import { MFA } from '@/constants/mfa';

import {
  currentProfile,
  getCurrentProfileAlias,
  getCurrentProfileType,
  getCurrentProfileUuid,
  getProfileByUUID,
  getSelectedProfile,
  hasBusinessProfile,
  isPersonalProfileActive,
  profileCollection,
  setCurrentProfile,
} from '@/stores/User/Profile/ProfileMapper';
import { setFlavor } from '@/stores/Theme/ThemeMapper';
import {
  composeUserTrackingData,
  getPageTrackingData,
  setPersistentDataForUser,
} from '@/helpers/user/tracking/trackingData';
import { GTM_EVENTS } from '@/constants/gtm';
import { fetchUserAccount, getPersonalInformation, getUserAddressCountry } from '@/stores/User/UserMapper';
import { fetchCSOperator, getCurrentCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import { MFA_ROUTE_NAMES } from '@Account/MFAVerification/router/routes-names';
import SharedRouteNames from '@Shared/router/routes-names';
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, LOGOS_KEYNAMES } from '@/constants/assetsRepository';
import { initChatBot, isChatVisibleOnRoute, updateChatBotParams } from '@/utils/Chabot/chatbot.utils';
import { FLEET_TYPES } from '@/constants/fleetTypes.const';

import { useStore } from 'vuex-composition-helpers/dist';
import { useAuthenticatedUser } from '@/composable/authenticatedUser/useAuthenticatedUser';

import { getPaymentMethodsInfo } from '@Shared/Payments/PaymentMethodsHub/getPaymentMethodsInfo';

const UseMobileComponent = () => import(/* webpackChunkName: "components" */'@Shared/components/UseMobile/UseMobile');
const Loading = () => import(/* webpackChunkName: "components" */'@Shared/components/Loader/Loader');

const EnvLabel = () => import(/* webpackChunkName: "components" */'@/components/EnvLabel/EnvLabel');

export default {
  name: 'AppView',

  components: {
    Loading,
    UseMobileComponent,
    EnvLabel,
    FeedbackModalComponent,
  },

  mixins: [
    EventHandlerMixin,
    LoaderMixin,
    SegmentMixin,

    // mixins ported from removed AuthenticatedUserMixin
    BookingTypeMixin,
    ProfileMixin,
    TariffMixin,
    UserInsuranceMixin,
    CompanyMixin,
    CSOperatorMixin,
  ],

  provide() {
    return {
      rootInjectedPaymentMethods: computed(() => this.rootPaymentMethods),
      flavor: getValue(config, 'data.flavor'),
    };
  },

  setup() {
    const { accessToken, idpUuid, logout } = useAuth();
    const { userData } = useUserData();

    const store = useStore();
    const authenticatedUser = useAuthenticatedUser(store);

    const {
      setAuthenticatedUser,
      fetchAuthenticatedUser,
      fetchExtraUserData,
    } = authenticatedUser;

    return {
      accessToken,
      idpUuid,
      logout,
      setAuthenticatedUser,
      fetchAuthenticatedUser,
      fetchExtraUserData,
      store,
      userData,
    };
  },

  data() {
    return {
      auth: authService.instance,
      userDataFilled: false,
      userEmailVerificationChecked: false,
      featureFlagService: null,
      config: config.data,
      isWatchlistedModalOpen: false,
      modals: {
        active: {
          isOpen: false,
        },
      },
      mandatoryData: [],
      rootPaymentMethods: null,
    };
  },
  computed: {
    getMyActiveBookings,
    getMyRequestBookings,
    getCurrentCSOperator,
    employeePolicy,
    currentProfile,
    getCurrentProfileType,
    getCurrentProfileAlias,
    profileCollection,
    getCurrentProfileUuid,
    hasBusinessProfile,
    getProfileByUUID,
    getUserAddressCountry,
    getPersonalInformation,
    getSelectedProfile,
    isPersonalProfileActive,
    isOpenOrDedicatedFleet,
    ...mapGetters(USERDATA_NAMESPACE, {
      dedicatedFleetCSOperatorUuid: USERDATA_GETTERS.dedicatedFleetCSOperatorUuid,
    }),
    didomi() {
      /* eslint-disable-next-line */
      return typeof Didomi === 'undefined' ? null : Didomi;
    },
    showRouterView() {
      if (!this.isRouteLoaded) {
        return false;
      }
      const isAuthenticated = this.auth.isAuthenticated();
      const emailVerification = (isAuthenticated
        && this.userEmailVerificationChecked
        && this.isEmailVerificationRoute);

      return (!this.requireAuth && this.userDataFilled)
        || (isAuthenticated && this.userDataFilled)
        || emailVerification;
    },
    isRouteLoaded() {
      return !isNil(this.$route.name);
    },
    requireAuth() {
      return getValue(this, '$route.meta.requireAuth');
    },
    requireLoader() {
      return getValue(this, '$route.meta.requireLoader');
    },
    isEmailVerificationRoute() {
      return includes(EmailValidationRouteNames, this.$route.name);
    },
    requestByMobile() {
      return this.$route.query.mobile;
    },
    browserClassname: () => browserClassname(),

    flavorClass: () => `flavor-${toLowercaseString(config.data.flavor)}`,

    ...mapGetters(USERDATA_NAMESPACE, [
      'getProfilePermissions',
    ]),
  },

  watch: {
    '$route.name': {
      handler(newRouteName) {
        updateChatBotParams({
          hide_default_launcher: !isChatVisibleOnRoute(newRouteName),
        });
      },
    },
    getSelectedProfile(newUuid) {
      if (newUuid) {
        this.onChangeProfile();
        updateChatBotParams({
          profile_type: this.getCurrentProfileType,
        });
      }
    },
    userData: {
      handler() {
        const locale = getValue(this.userData, 'locale', navigator.language);
        updateChatBotParams({
          locale,
          name: `${getValue(this.userData, 'firstName', '')} ${getValue(this.userData, 'lastName', '')}`,
          language_override: locale,
        });
      },
      deep: true,
    },
  },
  created() {
    this.setFlavor(getValue(config, 'data.flavor'));
    window.sessionStorage.removeItem('CSOperator/csoperators');
    this.setTitleAndFavicon();
    if (this.isRouteLoaded) {
      this.onRouteLoaded();
      return;
    }
    this.genericUserBlock = genericUserBlock;
    const unwatch = this.$watch('isRouteLoaded', () => {
      this.onRouteLoaded();
      unwatch();
    });
  },
  beforeDestroy() {
    this.eventHandler.$off(this.events.UPDATE_ACTIVE_BOOKINGS, this.updateActiveBookings);
  },

  methods: {
    fetchCSOperator,
    fetchMyActiveBookings,
    fetchMyRequestBookings,
    getEmployeePolicy,
    setCurrentProfile,
    fetchUserAccount,
    setFlavor,

    ...mapMutations(USERDATA_NAMESPACE, {
      setProfilePermissions: USERDATA_MUTATIONS.SET_PROFILE_PERMISSIONS,
      setCircleManagerPermissions: USERDATA_MUTATIONS.SET_CIRCLE_MANAGER_PERMISSIONS,
    }),

    setTitleAndFavicon() {
      document.title = getValue(config, 'data.csOperator.appTitle');

      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'icon';
      link.href = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.LOGO, LOGOS_KEYNAMES.FAVICON);
      document.getElementsByTagName('head')[0].appendChild(link);
    },

    async bootChatBot() {
      let childOperator;
      let appId = getValue(this.getCurrentCSOperator, 'configuration.chatbot_app_id');

      if (this.dedicatedFleetCSOperatorUuid) {
        childOperator = await this.fetchCSOperator(this.dedicatedFleetCSOperatorUuid);
        appId = getValue(childOperator, 'configuration.chatbot_app_id', null);
      }

      initChatBot({
        appId,
        enabledVendors: getValue(result(this, 'didomi.getUserStatus'), 'vendors.global.enabled', []),
        userData: this.userData,
        profileType: this.getCurrentProfileType,
        fleetType: !this.hasBusinessProfile ? FLEET_TYPES.open : this.isOpenOrDedicatedFleet,
        operatorUuid: getValue(this.getCurrentCSOperator, 'uuid'),
        routeName: this.$route.name,
      });
    },

    async setRootPaymentMethods() {
      const operatorUuid = getValue(this.getCurrentCSOperator, 'uuid');

      let operator = null;

      if (operatorUuid) {
        operator = await this.fetchCSOperator(operatorUuid);
      }

      this.rootPaymentMethods = await getPaymentMethodsInfo(this.store, operator);
    },

    async onChangeProfile() {
      initAuthAxiosClientConfig();

      await this.updateActiveBookings();
      await this.setRootPaymentMethods();
    },

    async updateActiveBookings() {
      if (!this.getCurrentProfileUuid) {
        return;
      }

      let usesPreBooking = false;
      const companyOperatorUuid = getValue(this.userData, 'company.dedicated_fleet_cs_operator');

      if (companyOperatorUuid) {
        const csOperator = await this.fetchCSOperator(companyOperatorUuid);
        usesPreBooking = getValue(csOperator, 'configuration.uses_pre_booking');
      }

      const payloadActiveBookings = {
        profileUuid: this.getCurrentProfileUuid,
        params: {
          profileUuid: this.getCurrentProfileUuid,
          page: 1,
          history: 0,
          booking_types: [BOOKING_TYPES.carsharing, BOOKING_TYPES.longDistance],
          items: 100,
        },
        fn: this.fetchCSOperator,
      };

      const payloadPreBookings = {
        profileUuid: this.getCurrentProfileUuid,
      };

      await Promise.all([
        this.fetchMyActiveBookings(payloadActiveBookings),
        usesPreBooking && this.fetchMyRequestBookings(payloadPreBookings),
      ]);
    },

    showErrorModalPage(payload) {
      this.modals.active = {
        ...genericErrorArgs(this.$t),
        ...payload,
        description: getValue(payload, 'error', ''),
        primaryCallToAction: this.logout,
        isOpen: true,
      };

      delay(noop, DELAY.extraLong);
      delay(this.logout, DELAY.extraLong);
    },

    async installFeatureFlagService(data) {
      const launchDarklyInstance = createLaunchDarklyInstance(data);
      const flags = await getFlags(launchDarklyInstance);
      const camelCaseFlags = toCamelCaseFlags(flags);
      const service = { flags: camelCaseFlags };
      this.featureFlagService = service;

      Vue.mixin({
        beforeCreate() {
          this.$featureFlag = service;
        },
      });
    },
    initializeSegmentTracking(userDetailsResponse) {
      const trackingId = getValue(userDetailsResponse, 'tracking_id', null);
      if (this.isSegmentLoaded()) {
        const identifyProps = this.getIdentifyProperties(userDetailsResponse);
        this.trackSegmentIdentify(trackingId, identifyProps);
      }
    },
    getOperatorRequest(userDetailsResponse) {
      initAuthAxiosClientConfig();
      const { cs_provider_uuid: operatorUuid } = userDetailsResponse;
      return this.fetchCSOperator(operatorUuid)
        .then(async operator => {
          await this.fetchCSOperatorSuccessCallback({
            operator,
            userDetailsResponse,
          });
          this.fetchMaintenanceConditions();
        });
    },
    async onRouteLoaded() {
      this.eventHandler.$on(this.events.SHOW_ERROR_MODAL_PAGE, this.showErrorModalPage);

      if (!this.requireAuth) {
        initGuestAxiosClientConfig();

        const featureFlagData = getInstancePayload({
          user: {
            uuid: this.$cookie.get('launchdarkly_key'),
          },
        });

        await this.installFeatureFlagService(featureFlagData);

        // It's used to identify when Feature flag is loaded and ready to be used.
        this.userDataFilled = true;
        return;
      }

      this.eventHandler.$on(this.events.UPDATE_ACTIVE_BOOKINGS, this.updateActiveBookings);

      try {
        // If data is not loaded, call to UserDetails endpoint
        // and create Sift Science script to track the user interaction
        if (!this.userDataFilled) {
          // Check if user has idp_uuid in authService instance
          if (!this.idpUuid) {
            this.logout();
            return;
          }

          initAuthAxiosClientConfig({ locale: '' });

          await googleMapsLoader.createInstance();

          // Fetch User details and keep the response in Store
          const userDetailsResponse = await external.userCompanyUserAccount.getUserDetailFromIdp(this.idpUuid);
          validateUserDetailsResponse(userDetailsResponse);
          // define language
          const userUUID = userDetailsResponse.uuid;
          const userLocaleCode = userDetailsResponse.locale_code;
          const forcedLanguage = languageService.getLocaleFromLanguageCode(userLocaleCode);
          moment.locale(forcedLanguage);
          languageService.setForceLanguage(forcedLanguage);
          Translations.setDefaultLanguage(forcedLanguage);

          await this.setAuthenticatedUser(userDetailsResponse);

          this.setActiveProfile(userUUID);

          const operatorRequest = this.getOperatorRequest(userDetailsResponse);

          this.mandatoryData.push(operatorRequest);

          await this.fetchExtraUserData(userDetailsResponse);
          await this.fetchUserAccount({ userUuid: userDetailsResponse.uuid });

          initAuthAxiosClientConfig();

          setPersistentDataForUser(this.userData);

          const { myBookings, myBookingsActive, myBookingsHistory } = BookingRouteNames;
          const bookingRoutes = [myBookings, myBookingsActive, myBookingsHistory];
          if (!includes(bookingRoutes, this.$route.name)) {
            await this.updateActiveBookings();
          }

          this.setupEmployeePolicy();

          const currentProfileUuid = getValue(this, 'currentProfile.uuid', '');
          this.fetchPermissions(userUUID, currentProfileUuid);

          const userProfiles = getValue(this, 'profileCollection', null);
          this.fetchProfilesPermissions(reject(userProfiles, { uuid: currentProfileUuid }));

          if (userUUID) {
            siftScienceSnippet.addScript(userUUID);
            siftScienceSnippet.track();
          }

          this.initializeSegmentTracking(userDetailsResponse);

          await this.setRootPaymentMethods();

          const emailVerified = result(this, 'userData.isUserEmailVerify');

          if (!emailVerified && !this.isEmailVerificationRoute) {
            this.userEmailVerificationChecked = true;
            this.$router.push({ name: EmailValidationRouteNames.UserEmailVerification });
          } else {
            Promise.all(this.mandatoryData).then(async () => {
              this.userEmailVerificationChecked = true;
              this.userDataFilled = true;
            });
          }

          Vue.use(
            MuiAlgoliaPlugin,
            {
              appID: config.data.algoliaAppId,
              prefix: config.data.algoliaPrefix,
              API_URL: config.data.phoenixApiUrl,
              headers: {
                Authorization: `${this.accessToken}`,
              },
            },
          );
          if (!this.$gtm.enabled()) {
            return;
          }
          await this.initialVisitTrackEvent();
        }
      } catch (error) {
        await this.onRouteLoadedErrorHandler(error);
      }
    },

    async initialVisitTrackEvent() {
      if (isNil(this.getPersonalInformation)) {
        await this.fetchUserAccount({ userUuid: getValue(this, 'userData.uuid', '') });
      }
      window.dataLayer?.push({
        page_data: null,
      });
      const userTrackingData = composeUserTrackingData({
        trackingId: getValue(this, 'userData.company.trackingId', null),
        userCompanyName: getValue(this, 'userData.company.commercial_name', ''),
        currentProfile: this.getCurrentProfileAlias,
        country: this.getUserAddressCountry,
      });
      const language = getValue(this, 'userData.locale', null);
      const country = getValue(this, 'userData.country', null);
      const location = getValue(window, 'location');

      const pageData = getPageTrackingData({
        country, language, document, location, window, newRoute: this.$route,
      });

      this.$gtm.trackEvent({
        event: GTM_EVENTS.pageView,
        user_data: userTrackingData,
        page_data: pageData,
      });
    },

    fetchProfilesPermissions(userProfiles = []) {
      each(userProfiles, profile => {
        external.userCompanyAcl.getUserPermissions(profile.uuid)
          .catch((error = {}) => {
            const message = getValue(error, 'response.data.message', error.message);
            logger.message(message, LOG_TYPE.error);
          })
          .then(response => {
            const permissions = concat(response);
            this.setProfilePermissions({ profileUuid: profile.uuid, permissions });
          });
      });
    },

    fetchPermissions(userUuid, profileUuid) {
      const profilePermissionsRequest = external.userCompanyAcl.getUserPermissions(profileUuid);
      const circlePermissionsRequest = external.userCompanyAcl.getCircleManagerPermissions({ user_uuid: userUuid });

      const requests = Promise.all([
        profilePermissionsRequest,
        circlePermissionsRequest,
      ]).catch((error = {}) => {
        const message = getValue(error, 'response.data.message', error.message);
        logger.message(message, LOG_TYPE.error);
      }).then(([profilePermissions, circlePermissions]) => {
        const permissions = concat(profilePermissions);
        this.setProfilePermissions({ profileUuid, permissions });
        aclService.setUserPermissions(permissions);

        this.setCircleManagerPermissions(circlePermissions);
        aclService.setCirclesPermissions(circlePermissions);
      });

      this.mandatoryData.push(requests);
    },

    setActiveProfile(userUuid) {
      const lastProfileUUIDUsed = this.$cookie.get(`last_account_used_${userUuid}`);
      if (lastProfileUUIDUsed) {
        const profileByUUID = this.getProfileByUUID(lastProfileUUIDUsed);
        if (profileByUUID) {
          this.setCurrentProfile(profileByUUID);
        }
      } else if (this.hasBusinessProfile) {
        this.setCurrentProfile(this.hasBusinessProfile);
      }
    },

    /**
     * @typedef {Object} fetchCSOperatorSuccessCallbackParams
     * @property {Object} userDetailsResponse - contains user data
     * @property {Object} operator - contains operator information
     *
     * The callback to run after fetching CS operator successfully
     * @param {fetchCSOperatorSuccessCallbackParams}
     */
    async fetchCSOperatorSuccessCallback({
      operator,
      userDetailsResponse,
    }) {
      this.setCurrentCSOperator(operator);

      const { uuid, name } = operator;
      const featureFlagData = getInstancePayload({
        user: userDetailsResponse,
        operator: { uuid, name },
      });

      await this.installFeatureFlagService(featureFlagData);

      const hotjarFlag = getValue(this, 'featureFlagService.flags.hotjarIdentification', null);

      const isIdentificable = shouldBeIdentifiedInHotjar(userDetailsResponse);

      if (hotjarFlag && isIdentificable) {
        const hotjarPayload = getHotjarPayload({
          languageService,
          user: userDetailsResponse,
          operator: { uuid, name },
        });

        identifyHotjarUser(hotjarPayload);
      }

      invoke(this, 'didomi.on', 'consent.changed', this.bootChatBot);
      await this.bootChatBot();
    },

    fetchMaintenanceConditions() {
      if (getValue(this, 'featureFlagService.flags.maintenanceInProgress', false)) {
        this.$router.push({ name: SharedRouteNames.maintenance }).catch(navigationErrorHandler);
      }
    },

    /**
     * Get Employee policy if user belongs to a company and it has activated the company page.
     * We will cache the response of endpoint to be used on
     */
    setupEmployeePolicy() {
      const companyUuid = getValue(this, 'userData.company.uuid', '');

      if (companyUuid) {
        this.getEmployeePolicy({ companyUuid });
      }
    },

    /**
     * Handles the error caught in `onRouteLoaded` function
     * @param {Object} error - contains the error data caught in `onRouteLoaded` function
     */
    async onRouteLoadedErrorHandler(error) {
      this.isWatchlistedModalOpen = validateIsUserWatchlisted(error);
      this.toggleLoaderStatus(false);

      if (this.isWatchlistedModalOpen) {
        return;
      }

      // This flow should not ever happen when impersonating another user,
      // as from BE they wont throw MFA exceptions when `impersonated` header is sent with a valid value
      await this.installFeatureFlagService(getInstancePayload());

      if (getValue(error, 'response.data.key', '') === MFA.mfaRequiredErrorKey) {
        // We need to manually call the resend code endpoint because from BE they are not automatically sending the email to the user
        // Here we set the flag to allow us to send the code automatically from the MFA verification view only once
        const resolvedRoute = this.$router.resolve({ name: MFA_ROUTE_NAMES.mfaVerification });
        const isMfaVerificationRouteResolved = (getValue(resolvedRoute, 'resolved.name', '') === MFA_ROUTE_NAMES.mfaVerification);

        if (this.idpUuid && isMfaVerificationRouteResolved) {
          rehydrate.set(MFA.sendFirstCodeFlagStorageKey, getIdpUuid(this.idpUuid), true);
          window.location = resolvedRoute.href;

          return;
        }
      }

      const errorMessage = getValue(error, 'message', null) || getValue(error, 'exception_message', null);
      logger.message(errorMessage, LOG_TYPE.error);
      this.showErrorModalPage({ ...genericErrorArgs(this.$t), error: errorMessage });
    },
  },

};
</script>
